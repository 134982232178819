<template>
  <Portlet
    :title="$t('menu.bulkCommands')"
    icon="terminal"
    class="bulkCommands"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <BulkCommandsList
      ref="bulkCommandsList"
      @reloadAuditLogs="reloadAuditLogs"
    /> 
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-bulk-commands="true"
      @reload="reloadBulkCommandsList"
    />
  </Portlet>
</template>

<script>
export default {
    name: 'BulkCommands',
    components: {
        BulkCommandsList: () => import('@/components/Device/BulkCommandsList.vue'),
        DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
    },
    metaInfo () {
        return {
            title: this.$t('menu.applicationSettings')
        }
    },
    methods: {
      showSidebarAdd () {
        this.$refs.bulkCommandsList.showSidebarAdd();
      },
      reloadAuditLogs () {
        this.$refs.databaseAuditLogList.reloadAuditLogs();
      },
      reloadBulkCommandsList () {
        this.$refs.bulkCommandsList.getBulkCommands();
      }
    }
}
</script> 